import { FOOTER } from "../../appConstants";

const Footer = () => {
    return (
      <div className="App-footer">
        { FOOTER }
      </div>
    );
}

export default Footer;
