import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PaymentComplete = () => {

  const [iframeHeight, setIframeHeight] = useState('0px');

  useEffect(() => {
      const handleResize = () => {
        const contentHeight = document.documentElement.scrollHeight;
        setIframeHeight(contentHeight + 'px');
      };

      // Initial height calculation
      handleResize();

      // Add resize event listener
      window.addEventListener('resize', handleResize);

      // Cleanup
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
      <>
        <br />
        <div>Thank you for your payment; you will receive a payment confirmation via email. You can close this page now.</div>
        <br />
      </>
    );
}

export default PaymentComplete;
